export default function useStatusBackgroundColour(status: string): string {
    switch (status) {
        case 'draft':
        case 'pending':
        case 'null':
            return 'bg-neutral-200 text-gray-600';
        case 'warning':
        case 'validation_requested':
        case 'reserves_raised_awaiting_client_signature':
        case 'reserves_raised_awaiting_tenant_signature':
        case 'reserves_raised_tenant_signature':
        case 'reserves_removed_awaiting_client_signature':
        case 'completed_awaiting_tenant_signature':
            return 'bg-orange-200 text-orange-900';
        case 'sent':
        case 'processed':
            return 'bg-blue-200 text-blue-900';
        case 'accepted':
        case 'paid':
        case 'delivered':
        case 'success':
        case 'validated':
        case 'completed':
            return 'bg-green-200 text-green-900';
        case 'overdue':
        case 'rejected':
        case 'refused':
        case 'failed':
        case 'external-failure':
        case 'internal-failure':
        case 'error':
            return 'bg-red-200 text-red-900';

        default:
            return 'bg-neutral-100 text-gray-600';
    }
}
